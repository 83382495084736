// /**
//  * Variation Buttons Script
//  * This script handles the functionality of WooCommerce variation buttons,
//  * including dynamic updates to the color name display, button enabling/disabling,
//  * and syncing with variation dropdowns.
//  *
//  * Features:
//  * 1. Dynamically updates the color name display based on the selected variation.
//  * 2. Synchronizes button states with the WooCommerce variation dropdown.
//  * 3. Handles mobile-specific behavior (scrolls to the top when variations are updated).
//  * 4. Updates the main product image when a variation button or image is clicked.
//  * 5. Adds a visual indicator (underline) for the selected variation image.
//  * 6. Compatible with "woocommerce_update_variation_values" events.
//  */

// document.addEventListener("DOMContentLoaded", function () {
//   /**
//    * Initialize the default color name on page load.
//    * Ensures the selected variation button's color name is displayed.
//    */
//   function initializeDefaultColorName() {
//     const buttons = document.querySelectorAll(
//       ".variation-buttons .variation-button"
//     );
//     buttons.forEach((button) => {
//       const thisName = button.getAttribute("name");
//       const thisVal = button.getAttribute("value");
//       const colorName = button.getAttribute("data-name"); // Human-readable name
//       const productContainer = button.closest(".product");
//       const colorSelect = productContainer.querySelector(
//         `select[name="${thisName}"]`
//       );
//       const currentVal = colorSelect.value;

//       // Update color name if the button's value matches the selected option
//       if (currentVal === thisVal) {
//         const colorNameElement = productContainer.querySelector(".color-name");
//         if (colorNameElement) {
//           colorNameElement.textContent = colorName;
//         }
//       }
//     });
//   }

//   /**
//    * Update variation image and color name when clicked.
//    */
//   function updateVariation(colorName, imageUrl, button) {
//     // Update the color name display
//     const colorNameElement = document.querySelector(".color-name");
//     if (colorNameElement) {
//       colorNameElement.textContent = colorName;
//     }

//     // Update the main product image
//     // const mainImage = document.querySelector(
//     //   ".woocommerce-product-gallery__image img"
//     // );
//     // if (mainImage) {
//     //   mainImage.src = imageUrl;
//     //   mainImage.dataset.src = imageUrl; // Update lazy-loaded src if applicable
//     // }

//     // Remove "selected" class from all variation images
//     document.querySelectorAll(".variation-image").forEach((img) => {
//       img.classList.remove("selected");
//     });

//     // Add "selected" class to the clicked variation image
//     if (button) {
//       const variationImage = button.nextElementSibling; // Assuming image follows button
//       if (
//         variationImage &&
//         variationImage.classList.contains("variation-image")
//       ) {
//         variationImage.classList.add("selected");
//       }
//     }
//   }

//   /**
//    * Event listener for clicking variation buttons.
//    * Updates the color name dynamically and syncs the variation dropdown.
//    */
//   document.addEventListener("click", function (event) {
//     const el = event.target.closest(".variation-buttons .variation-button");
//     if (el) {
//       const productContainer = el.closest(".product");
//       const attributeName = el.getAttribute("name");
//       const newVal = el.getAttribute("value");
//       const colorSelect = productContainer.querySelector(
//         `select[name=\"${attributeName}\"]`
//       );

//       // If the user clicks a different value than what's currently selected
//       if (colorSelect && colorSelect.value !== newVal) {
//         colorSelect.value = newVal;
//         // This triggers WooCommerce's variation scripts to update images, price, etc.
//         colorSelect.dispatchEvent(new Event("change", { bubbles: true }));
//       }
//     }
//   });

//   /**
//    * Event listener for clicking variation images.
//    * Clicking the image updates the main product image and selects the variation.
//    */
//   document.addEventListener("click", function (event) {
//     const el = event.target.closest(".variation-image");
//     if (el) {
//       const button = el.previousElementSibling; // Get the associated button
//       if (button) {
//         const colorName = button.getAttribute("data-name");
//         const imageUrl = button.getAttribute("data-image");
//         updateVariation(colorName, imageUrl, button);

//         // Trigger variation selection
//         button.click();
//       }
//     }
//   });

//   /**
//    * Event listener for WooCommerce's "woocommerce_update_variation_values" event.
//    * Updates button states (enabled/disabled) based on variation stock and synchronizes
//    * the color name display.
//    */
//   document.addEventListener("woocommerce_update_variation_values", function () {
//     const buttons = document.querySelectorAll(
//       ".variation-buttons .variation-button"
//     );
//     buttons.forEach((button) => {
//       const thisName = button.getAttribute("name");
//       const thisVal = button.getAttribute("value");
//       const colorName = button.getAttribute("data-name"); // Human-readable name
//       const productContainer = button.closest(".product");
//       const colorSelect = productContainer.querySelector(
//         `select[name="${thisName}"]`
//       );
//       const currentVal = colorSelect.value;

//       // Reset button state and disable out-of-stock variations
//       button.removeAttribute("disabled");
//       const option = colorSelect.querySelector(`option[value="${thisVal}"]`);
//       if (option && option.disabled) {
//         button.setAttribute("disabled", "true");
//       }

//       // Update the color name if the current value matches the button's value
//       if (currentVal === thisVal) {
//         const colorNameElement = productContainer.querySelector(".color-name");
//         if (colorNameElement) {
//           colorNameElement.textContent = colorName;
//         }
//       }
//     });
//   });

//   // Initialize the default color name on page load
//   initializeDefaultColorName();
// });
/**
 * Variation Buttons Script
 * ---------------------------------------
 * This script handles the functionality of WooCommerce variation buttons,
 * including:
 * 1. Dynamically updating the color name display based on the selected variation.
 * 2. Synchronizing button states with WooCommerce variation dropdowns.
 * 3. Updating images, if desired.
 * 4. Adding a visual indicator (underline) for the selected variation image.
 * 5. Compatible with "woocommerce_update_variation_values" events.
 */

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Initialize the default color name on page load.
   * Ensures the selected variation button's color name is displayed.
   */
  function initializeDefaultColorName() {
    const buttons = document.querySelectorAll(
      ".variation-buttons .variation-button"
    );
    buttons.forEach((button) => {
      // Find the nearest product container.
      // Adjust the selector as needed if .product isn't correct for your theme.
      const productContainer = button.closest(".product");
      if (!productContainer) return;

      const attributeName = button.getAttribute("name");
      const buttonVal = button.getAttribute("value");
      const colorName = button.getAttribute("data-name");
      const colorSelect = productContainer.querySelector(
        `select[name="${attributeName}"]`
      );
      if (!colorSelect) return;

      // If the default selected variation matches this button's value
      if (colorSelect.value === buttonVal) {
        const colorNameElement = productContainer.querySelector(".color-name");
        if (colorNameElement) {
          colorNameElement.textContent = colorName;
        }
      }
    });
  }

  /**
   * Update the variation color name and highlight the selected variation image.
   */
  function updateVariation(colorName, imageUrl, button) {
    // Scope the update to the *same product*
    const productContainer = button.closest(".product");
    if (!productContainer) return;

    // Update the color name display
    const colorNameElement = productContainer.querySelector(".color-name");
    if (colorNameElement) {
      colorNameElement.textContent = colorName;
    }

    // If you want to update the main image, un-comment below:
    /*
    const mainImage = productContainer.querySelector(".woocommerce-product-gallery__image img");
    if (mainImage) {
      mainImage.src = imageUrl;
      // Update data-src if using lazy load
      mainImage.dataset.src = imageUrl;
    }
    */

    // Remove "selected" class from any variation images in this product
    productContainer.querySelectorAll(".variation-image").forEach((img) => {
      img.classList.remove("selected");
    });

    // Add "selected" to the clicked variation image
    const variationImage = button.nextElementSibling;
    if (
      variationImage &&
      variationImage.classList.contains("variation-image")
    ) {
      variationImage.classList.add("selected");
    }
  }

  // Listen for clicks on variation buttons
  document.addEventListener("click", function (event) {
    // Variation button clicked?
    const btn = event.target.closest(".variation-buttons .variation-button");
    if (btn) {
      const productContainer = btn.closest(".product");
      if (!productContainer) return;

      const attributeName = btn.getAttribute("name");
      const newVal = btn.getAttribute("value");
      const colorSelect = productContainer.querySelector(
        `select[name="${attributeName}"]`
      );

      // Only trigger if user clicks a different value than the current
      if (colorSelect && colorSelect.value !== newVal) {
        colorSelect.value = newVal;
        // Let WooCommerce know to update price, images, etc.
        colorSelect.dispatchEvent(new Event("change", { bubbles: true }));
      }
    }

    // Variation image clicked?
    const img = event.target.closest(".variation-image");
    if (img) {
      // We assume the button is the previous sibling
      const button = img.previousElementSibling;
      if (button) {
        const colorName = button.getAttribute("data-name");
        const imageUrl = button.getAttribute("data-image");
        updateVariation(colorName, imageUrl, button);

        // Also trigger the button so that the variation is actually selected
        button.click();
      }
    }
  });

  // Listen for WC's "woocommerce_update_variation_values" event
  // to dynamically enable/disable buttons & re-sync color names
  document.addEventListener("woocommerce_update_variation_values", function () {
    const buttons = document.querySelectorAll(
      ".variation-buttons .variation-button"
    );
    buttons.forEach((button) => {
      const productContainer = button.closest(".product");
      if (!productContainer) return;

      const attributeName = button.getAttribute("name");
      const buttonVal = button.getAttribute("value");
      const colorName = button.getAttribute("data-name");
      const colorSelect = productContainer.querySelector(
        `select[name="${attributeName}"]`
      );
      if (!colorSelect) return;

      // Enable by default
      button.removeAttribute("disabled");

      // If the corresponding dropdown option is disabled, disable button
      const option = colorSelect.querySelector(`option[value="${buttonVal}"]`);
      if (option && option.disabled) {
        button.setAttribute("disabled", "true");
      }

      // Update color name display if this buttonVal is currently selected
      if (colorSelect.value === buttonVal) {
        const colorNameElement = productContainer.querySelector(".color-name");
        if (colorNameElement) {
          colorNameElement.textContent = colorName;
        }
      }
    });
  });

  // On page load, initialize the default color name
  initializeDefaultColorName();
});
